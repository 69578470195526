import appConfig from "./config"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"

type HornetAppConfig = AppConfig & {
  aws: {
    region: string
    cognito: {
      identityPoolId: string
    }
  }
}

export default appConfig as HornetAppConfig
