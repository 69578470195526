export type ApiServiceEndpointType = {
  url: string
  hasAuth: boolean
  method: "GET" | "POST" | "PUT" | "DELETE"
  api: "hornet" | "shop" | "community" | "quickies"
}

const ApiServiceEndpoints = {
  ApiLoginPost: {
    url: `{appUrl}/api/login?{queryParams}`,
    hasAuth: false,
    method: "POST",
    api: "hornet",
  },
  CreateAccountPost: {
    url: `/accounts`,
    hasAuth: false,
    method: "POST",
    api: "hornet",
  },
  UpgradeAccountPost: {
    url: `/accounts`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  CreateSessionPost: {
    url: `/session`,
    hasAuth: false,
    method: "POST",
    api: "hornet",
  },
  SessionDelete: {
    url: `/session`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  ResetPasswordPost: {
    url: `/password_resets`,
    hasAuth: false,
    method: "POST",
    api: "hornet",
  },
  AccountPut: { url: `/account`, hasAuth: true, method: "PUT", api: "hornet" },
  AccountDelete: {
    url: `/account`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  AccountProfilePut: {
    url: `/account/profile`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  PhotosPost: { url: `/photos`, hasAuth: true, method: "POST", api: "hornet" },
  PhotosPut: {
    url: `/photos/{photoId}`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  PhotosDelete: {
    url: `/photos/{photoId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  PhotosSlotsPut: {
    url: `/photos/slots`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  PhotosSharePost: {
    url: `/photos/share`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  MemberGet: {
    url: `/members/{profileId}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MemberFollowersGet: {
    url: `/members/{profileId}/followers?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MemberFavouritesGet: {
    url: `/members/{profileId}/favourites?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  ProfileAwardsGet: {
    url: "/awards",
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  ProfileAwardsReactionPut: {
    url: "/awards/{awardId}/reaction",
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  AwardsGet: {
    url: "/award_types",
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersNearGet: {
    url: `/members/near?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersRecentGet: {
    url: `/members/recent?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersExploreGet: {
    url: `/members/explore?lat={lat}&lng={lng}&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersViewedMeGet: {
    url: `/members/viewed_me?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersFavoritesGet: {
    url: `/favourites/favourites?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersFollowersGet: {
    url: `/favourites/fans?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersMatchesGet: {
    url: `/favourites/matches?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersUsernameGet: {
    url: `/members/search?username={username}&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersHashtagGet: {
    url: `/members/search?hashtags={hashtag}&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersHealthAmbassadorsGet: {
    url: `/grids/health_ambassador?grid=health_ambassador&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersBetaTestersGet: {
    url: `/grids/community_badge/beta_tester?grid=beta_tester&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MembersFeaturedUsersGet: {
    url: `/grids/community_badge/featured_guy?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  GuysSearchByHashtagGet: {
    url: `/hashtags/suggest?query=%23{hashtag}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  GuysSearchByUsernameGet: {
    url: `/members/search?username=%40{username}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  GuysSearchByPopularHashtagsGet: {
    url: `/hashtags/popular`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  GridFiltersGet: {
    url: `/filters`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  GridFiltersPost: {
    url: `/filters`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  InboxGet: {
    url: `/messages/conversations?inbox={inbox}&page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  ConversationGet: {
    url: `/messages/{conversationId}/conversation?before={beforeToken}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  ConversationDelete: {
    url: `/messages/{conversationId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  InboxSeenPut: {
    url: `/messages/inbox_seen?inbox={inbox}`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  InboxSetDefaultPut: {
    url: `/messages/set_default_inbox?inbox={inbox}`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  MessagePost: {
    url: `/messages`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  MessagesConversationGet: {
    url: `/messages/{conversationId}/conversation?before={beforeToken}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MessageReactionTypesGet: {
    url: `/message_reaction_types`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MessageDelete: {
    url: `/messages/{messageRealId}/delete_message?recipient_id={conversationId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  MessageReactionGet: {
    url: `/messages/{messageRealId}/reactions?participant_id={participantId}&per_page=10`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  MessageReactionPost: {
    url: `/messages/{messageRealId}/reactions`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  MessageReactionDelete: {
    url: `/messages/{messageRealId}/reactions/{reactionId}?participant_id={participantId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  StickersGet: {
    url: `/app_stores/apple/products?product_class=stickers`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  SubscriptionDelete: {
    url: `/app_stores/web/transactions/cancel`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  ReportCancellationReasonPost: {
    url: `/community/wp-json/wp/v2/user-feedback`,
    hasAuth: true,
    method: "POST",
    api: "community",
  },
  FavouritesDelete: {
    url: `/favourites/{profileId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  FavouritesPost: {
    url: `/favourites`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  ExploreIgnoresGet: {
    url: `/explore_ignores`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  BlocksGet: {
    url: `/blocks`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  BlocksPost: {
    url: `/blocks`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  BlocksDelete: {
    url: `/blocks/{profileId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  FiltersPost: {
    url: `/filters`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  FiltersDelete: {
    url: `/filters/{name}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  ReportsPost: {
    url: `/reports`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  ResendEmailVerificationPost: {
    url: `/account/resend_verification_email`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  ResendSmsVerificationPost: {
    url: `/sms_verification/resend`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  VerifySmsVerificationPost: {
    url: `/sms_verification/verify`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  RecaptchaVerificationPost: {
    url: `/profiles/verify`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  DeviceFingerprintsPost: {
    url: `/device_fingerprints`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  NotesGet: {
    url: `/notes/{profileId}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  NotesPost: {
    url: `/notes`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  FeedsNotificationsGet: {
    url: `/feeds/notifications?after={nextToken}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  NotificationDelete: {
    url: `/notifications/{id}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  NotificationMutePut: {
    url: `/notifications/{id}/mute`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  NotificationUnmutePut: {
    url: `/notifications/{id}/unmute`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  MarkAllAsReadPut: {
    url: `/messages/mark_all_conversations_as_read`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  MarkConversationAsReadPut: {
    url: `/messages/{profileId}/read`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
  QuickiesMembersGet: {
    url: `/quickies_members/near?page={page}&per_page={perPage}&lat={lat}&lng={lng}&radius={radius}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesMembersFbmGet: {
    url: `/quickies_members/near?page={page}&per_page={perPage}&lat={lat}&lng={lng}&fbm=true&radius={radius}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesMemberGet: {
    url: `/quickies_members/{memberId}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesPhotosPost: {
    url: `/quickies_photos`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesPhotosPut: {
    url: `/quickies_photos/{photoId}`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesPhotosDelete: {
    url: `/quickies_photos/{photoId}`,
    hasAuth: true,
    method: "DELETE",
    api: "quickies",
  },
  QuickiesPhotosSlotsPut: {
    url: `/quickies_photos/slots`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesProfilePut: {
    url: `/quickies_profile`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesLookupDataGet: {
    url: `/quickies_lookup_data/all`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesProfileActivatePost: {
    url: `/quickies_profile/activate`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesProfileDeactivatePut: {
    url: `/quickies_profile/deactivate`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  PublicIpCountryGet: {
    url: `/public/ip_country?key={key}`,
    hasAuth: false,
    method: "GET",
    api: "hornet",
  },
  QuickiesProfileOnlinePut: {
    url: `/quickies_profile/online`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesProfileOfflinePut: {
    url: `/quickies_profile/offline`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesJoinWaitListPost: {
    url: `https://hornet.com/community/wp-json/quickies/emails`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesFeedbackPost: {
    url: `https://hornet.com/community/wp-json/quickies/feedback`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesPointsOfInterestGet: {
    url: `/points_of_interest`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesFeedGet: {
    url: `/quickies_nearby_posts?lat={lat}&lng={lng}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesFeedPost: {
    url: `/quickies_nearby_posts`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesPointsOfInterestCheckInsGet: {
    url: `/point_of_interest_posts?page={page}&per_page={perPage}&point_of_interest_id={pointOfInterestId}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesPointsOfInterestCheckInPost: {
    url: `/point_of_interest_checkins`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesPointOfInterestGet: {
    url: `/points_of_interest/{pointOfInterestId}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesReportsPost: {
    url: `/quickies_members/{memberId}/report`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  PushNotificationsPost: {
    url: `/web_push_subscriptions`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  QuickiesMemberFromShareGet: {
    url: `/quickies_members/from_share_id?share_id={shareId}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesDickVerificationPost: {
    url: `/dick_verifications`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesStickersGet: {
    url: `/app_stores/web/products?product_class=hornet_x_sticker`,
    hasAuth: true,
    method: "GET",
    api: "hornet", // this is on purpose, as the only difference is in the query param
  },
  MemberPrivatePhotosGet: {
    url: `/members/{profileId}/gallery?page=1&per_page=10`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  QuickiesPrivatePhotosFromHornetGet: {
    url: `/quickies_profile/private_hornet_photos`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesPrivatePhotosFromHornetPost: {
    url: `/quickies_profile/private_hornet_photos`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesInviteCodesValidatePost: {
    url: `/quickies_invites/validate`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  HornetIpQualityCheckGet: {
    url: `/session/chksm`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  QuickiesUserInvitesGet: {
    url: `/quickies_invites`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  EntitlementHornetXGet: {
    url: `/entitlements/hornet_x`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  QuickiesCreateGroupPost: {
    url: `/hangouts`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesCreateGroupPhotoPost: {
    url: `/hangout_photos`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesUpdateGroupPut: {
    url: `/hangouts/{groupId}`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesViewGroupGet: {
    url: `/hangouts/{groupId}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesGroupsGet: {
    url: `/hangouts/near?page={page}&per_page={perPage}&lat={lat}&lng={lng}`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesDestroyGroupDelete: {
    url: `/hangouts/{groupId}`,
    hasAuth: true,
    method: "DELETE",
    api: "quickies",
  },
  QuickiesInviteToJoinGroupPost: {
    url: `/hangouts/{groupId}/invite_to_join`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesRequestToJoinGroupPost: {
    url: `/hangouts/{groupId}/request_to_join`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesSuggestedQuickiesProfilesToInviteGet: {
    url: `/hangouts/{groupId}/suggested_invites?filter={filter}`, // filter: recent_chats|favorites
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesGroupsDashboardGet: {
    url: `/hangout_dashboard`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesGroupsDashboardSeenPut: {
    url: `/hangout_dashboard/seen`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesAcceptRequestToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}/accept_request`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesRejectRequestToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}/reject_request`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesAcceptAllRequestToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/accept_all_requests`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesCancelRequestToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}/cancel_request`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesAcceptInviteToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}/accept_invite`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesDeclineInviteToJoinGroupPut: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}/decline_invite`,
    hasAuth: true,
    method: "PUT",
    api: "quickies",
  },
  QuickiesLeaveGroupDelete: {
    url: `/hangouts/{groupId}/hangout_memberships/{groupMembershipId}`,
    hasAuth: true,
    method: "DELETE",
    api: "quickies",
  },
  QuickiesGroupChatGet: {
    url: `/group_chats/{groupChatId}/group_chat_messages?before={beforeToken}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  QuickiesGroupChatMessagePost: {
    url: `/group_chats/{groupChatId}/group_chat_messages`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  QuickiesGroupChatMessageDelete: {
    url: `/group_chats/{groupChatId}/group_chat_messages/{messageId}`,
    hasAuth: true,
    method: "DELETE",
    api: "hornet",
  },
  QuickiesGroupChatsGet: {
    url: `/group_chats?page={page}&per_page={perPage}&parent_type=Quickies::Hangout`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  AdhocCampaignGet: {
    url: `/adhoc_campaigns/{id}`,
    hasAuth: true,
    method: "GET",
    api: "hornet",
  },
  QuickiesMapFiltersGet: {
    url: `/map_filters`,
    hasAuth: true,
    method: "GET",
    api: "quickies",
  },
  QuickiesMapFiltersPost: {
    url: `/map_filters`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  QuickiesSettingsPost: {
    url: `/settings`,
    hasAuth: true,
    method: "POST",
    api: "quickies",
  },
  CreateAwsRekognitionFaceLivenessSessionPost: {
    url: `/rekognition_liveness_checks`,
    hasAuth: true,
    method: "POST",
    api: "hornet",
  },
  UpdateAwsRekognitionFaceLivenessSessionPut: {
    url: `/rekognition_liveness_checks/{sessionId}`,
    hasAuth: true,
    method: "PUT",
    api: "hornet",
  },
}

type ApiServiceEndpointKeys = keyof typeof ApiServiceEndpoints

export const ApiServiceEndpoint = ApiServiceEndpoints as Record<
  ApiServiceEndpointKeys,
  ApiServiceEndpointType
>
