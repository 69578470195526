import { useCallback, useState } from "react"
import { useProfileVerificationApi } from "@hornet-web-react/core/contexts/profile-verification-context"
import { useRouter } from "next/router"

export function useProfileVerification() {
  const {
    openEmailProfileVerification,
    openSmsProfileVerification,
    openRecaptchaProfileVerification,
    openIpQualityProfileVerification,
  } = useProfileVerificationApi()

  const router = useRouter()
  const [isRoutingToVerify, setIsRoutingToVerify] = useState(false)

  return {
    verifyWithEmail: useCallback(async () => {
      openEmailProfileVerification()
    }, [openEmailProfileVerification]),

    verifyWithSms: useCallback(async () => {
      openSmsProfileVerification()
    }, [openSmsProfileVerification]),

    verifyWithRecaptcha: useCallback(async () => {
      openRecaptchaProfileVerification()
    }, [openRecaptchaProfileVerification]),

    // uses IPQ (3rd party) script that requires CSP 'unsafe-eval' so use the
    // `redirectToVerifyWithIpQuality` method to redirect to such page first
    verifyWithIpQuality: useCallback(
      async (redirectTo: string | undefined) => {
        openIpQualityProfileVerification(redirectTo)
      },
      [openIpQualityProfileVerification]
    ),

    redirectToVerifyWithIpQuality: useCallback(async () => {
      // prevent multiple redirects or redirect loop
      if (
        isRoutingToVerify ||
        window.location.pathname.includes("verify/ipq")
      ) {
        return
      }

      setIsRoutingToVerify(true)

      // preserve current query params
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set("redirect", router.asPath)

      // cannot router push, but need to do hard redirect because the CSP update
      // needs to be loaded fresh (otherwise the page still does not allow the
      // unsafe-eval)
      window.location.href = `/verify/ipq?${queryParams.toString()}`
    }, [router, isRoutingToVerify]),

    redirectToVerifyFace: useCallback(async () => {
      // prevent multiple redirects or redirect loop
      if (
        isRoutingToVerify ||
        window.location.pathname.includes("verify/face") ||
        window.location.pathname.includes("liveness_check")
      ) {
        return
      }

      setIsRoutingToVerify(true)

      // preserve current query params
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set("redirect", router.asPath)

      // cannot router push, but need to do hard redirect because the CSP update
      // needs to be loaded fresh (otherwise the page still does not allow the
      // unsafe-eval)
      window.location.href = `/verify/face?${queryParams.toString()}`
    }, [router, isRoutingToVerify]),
  }
}
