import { useCallback } from "react"
import TeapotMessageError from "@hornet-web-react/core/services/API/Errors/TeapotMessageError"
import NiceModal from "@ebay/nice-modal-react"
import useTranslation from "next-translate/useTranslation"

export function useFlashMessage() {
  const { t } = useTranslation()

  return {
    showOops: useCallback(
      (
        error?:
          | TeapotMessageError
          | Error
          | { message: string; heading?: string }
          | unknown
      ) => {
        if (error instanceof TeapotMessageError) {
          return NiceModal.show("FlashMessageModal", {
            text: error.teapotMessage,
            heading: error.teapotTitle,
          })
        }

        if (
          typeof error === "object" &&
          error !== null &&
          "message" in error &&
          "heading" in error
        ) {
          return NiceModal.show("FlashMessageModal", {
            text: error.message,
            heading: error.heading,
          })
        }

        // just show a generic error message in case of any error
        return NiceModal.show("FlashMessageModal", {
          text: t("core:hooks.use_flash_message.generic_error"),
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    showMessage: useCallback((text: string) => {
      return NiceModal.show("FlashMessageModal", {
        text,
      })
    }, []),

    showOopsWithRetry: useCallback(
      (
        retryLabel: string,
        retryAction: () => void,
        error?:
          | TeapotMessageError
          | Error
          | { message: string; heading?: string }
          | unknown,
        hideOk?: boolean
      ) => {
        if (error instanceof TeapotMessageError) {
          return NiceModal.show("FlashMessageModal", {
            text: error.teapotMessage,
            heading: error.teapotTitle,
            retry: {
              retryLabel,
              retryAction,
              hideOk,
            },
          })
        }

        if (
          typeof error === "object" &&
          error !== null &&
          "message" in error &&
          "heading" in error
        ) {
          return NiceModal.show("FlashMessageModal", {
            text: error.message,
            heading: error.heading,
            retry: {
              retryLabel,
              retryAction,
              hideOk,
            },
          })
        }

        // just show a generic error message in case of any error
        return NiceModal.show("FlashMessageModal", {
          text: t("core:hooks.use_flash_message.generic_error"),
          retry: {
            retryLabel,
            retryAction,
            hideOk,
          },
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  }
}
