import { z } from "zod"

export type ApiPreviewPhotoPayload = {
  id: number
  state: string
  slot: number
  is_public: boolean
  url: string
  full_url: string
  full_large_url: string
  thumbnail_url: string
  thumbnail_large_url: string
  square_url: string
  v6_full_url: string
}

export type ApiPhotoPayload = ApiPreviewPhotoPayload & {
  is_public: boolean
}

export type ApiFilter = {
  key: string
  category: string
  data: string | boolean | number | object
}

export const ApiEntitlement = z.object({
  entitlement: z.object({
    id: z.number(),
    feature: z.string(),

    // cannot coerce to date because it's not JSON serializable and it must be
    // because it's handed over from server to client
    expires_at: z.string().nullable(),

    metadata: z.object({
      product_id: z.string().nullable().optional(),
      state: z.string().nullable().optional(),
    }),

    entitlement_transaction: z
      .object({
        expiry_date: z.string(),
        cancelled: z.boolean(),
        product: z.object({
          app_store: z.string(),
          product_id: z.string(),
        }),
      })
      .optional(),
  }),
})
export type ApiEntitlement = z.infer<typeof ApiEntitlement>
