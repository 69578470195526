import { differenceInDays, differenceInHours, formatDuration } from "date-fns"
import {
  EntitlementFeature,
  EntitlementInterface,
} from "@hornet-web-react/core/types/entitlements"
import { ApiEntitlement } from "@hornet-web-react/core/types/api"

type EntitlementProps = Omit<EntitlementInterface, "timeLeft">

export function filterEntitlementsByExpiryDate(data: ApiEntitlement) {
  // never expires
  if (!data.entitlement.expires_at) {
    return true
  }

  return new Date() < new Date(data.entitlement.expires_at)
}

export function createEntitlement(data: ApiEntitlement) {
  return Entitlement.create(data)
}

class Entitlement implements EntitlementInterface {
  readonly id: EntitlementInterface["id"]
  readonly feature: EntitlementInterface["feature"]
  readonly expires_at: EntitlementInterface["expires_at"]
  readonly metadata: EntitlementInterface["metadata"]
  readonly entitlementTransaction: EntitlementInterface["entitlementTransaction"]

  private constructor(props: EntitlementProps) {
    this.id = props.id
    this.feature = props.feature
    this.expires_at = props.expires_at
    this.metadata = props.metadata
    this.entitlementTransaction = props.entitlementTransaction
  }

  private static normalize(data: ApiEntitlement) {
    let expiresAt = data.entitlement.expires_at
    if (!expiresAt) {
      const createExpiresAtInFuture = (): Date => {
        const now = new Date()
        const tenYearsLater = new Date()
        tenYearsLater.setFullYear(now.getFullYear() + 10)

        return tenYearsLater
      }
      expiresAt = createExpiresAtInFuture().toISOString()
    }

    return {
      id: String(data.entitlement.id),
      feature: data.entitlement.feature as EntitlementFeature,
      expires_at: new Date(expiresAt),
      metadata: {
        productId: data.entitlement.metadata?.product_id
          ? String(data.entitlement.metadata.product_id)
          : undefined,
        state: data.entitlement.metadata?.state
          ? String(data.entitlement.metadata.state)
          : undefined,
      },
      entitlementTransaction: data.entitlement.entitlement_transaction,
    }
  }

  static create(data: ApiEntitlement) {
    return new Entitlement(Entitlement.normalize(data))
  }

  get timeLeft() {
    if (new Date() >= this.expires_at) {
      return ""
    }

    const diffInDays = differenceInDays(this.expires_at, new Date())
    const diffInHours = differenceInHours(this.expires_at, new Date())

    let timeLeft = ""

    if (diffInDays > 0) {
      timeLeft += formatDuration({
        days: diffInDays,
      })
    }

    if (diffInHours % 24 > 0) {
      timeLeft += ` ${formatDuration({
        hours: diffInHours % 24,
      })}`
    }

    return timeLeft.trim()
  }
}
